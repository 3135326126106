import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { connect, useDispatch } from 'react-redux';
import { useSearchParams } from 'next/navigation';
import {
  locationsQuery,
  searchItemsQuery,
  searchVippedItemsQuery,
  searchVippedResidencesItemsQuery
} from '../../../queries';
import FallbackView from '../../FallbackView';
import Section from '../../Section';
import SectionList from '../../SectionList';
import ItemCard from '../../Cards/ItemCard';
import SectionPlaceholder from '../../Placeholders/SectionPlaceholder';
import ItemCardPlaceholder from '../../Placeholders/ItemCardPlaceholder';
import withEndlessScroll from '../../HOC/endlessScroll';
import {
  setChosenLocationNames,
  setCurrentCity,
  setCurrentState,
  setError,
  setLocationIds
} from '../../../actions';
import NewSearch from '../../NewSearch';
import ErrorIcon from '../../../public/static/images/search/icons/icon-error.svg';

const searchListRef = React.createRef();
const EndlessSearchItems = withEndlessScroll(SectionList);

const mapDispatchToProps = dispatch => {
  return {
    setErrorUI: errorState => dispatch(setError(errorState))
  };
};

const SearchPage = ({ transformedObj, setErrorUI }) => {
  const { t } = useTranslation();
  const formEl = useRef(null);
  const params = useSearchParams();
  const dispatch = useDispatch();
  const locationIds = useMemo(
    () => params.getAll('location_ids[]'),
    [params.getAll]
  );
  const locationsQueryVariables = {
    scope: 'ALL',
    limit: 1000
  };
  const { data } = useQuery(locationsQuery(locationsQueryVariables), {
    variables: locationsQueryVariables
  });
  const locations = data?.locations || [];
  useEffect(() => {
    if (locationIds.length) {
      const locationNames = locations
        .filter(location => locationIds.includes(location.id))
        .map(location => location.name.trim());
      dispatch(setChosenLocationNames(locationNames));
      dispatch(setLocationIds(locationIds));
    }
  }, [locationIds, data]);

  const resetForm = () => {
    const resetData = {
      city_id: '1',
      leased: 'false',
      category_id: '1'
    };
    formEl?.current?.reset(resetData);
    dispatch(setChosenLocationNames([]));
    dispatch(setLocationIds([]));
    dispatch(setCurrentCity('1'));
    dispatch(
      setCurrentState({
        ...resetData,
        category_touched: false
      })
    );
  };
  const [itemId, setItemId] = useState(null);
  const setCarousel = id => {
    return itemId === id ? null : setItemId(id);
  };

  const searchItemsQueryVariables = {
    first: 16,
    filter: transformedObj.filter,
    sort: transformedObj.sorting || 'BUMPED_AT_DESC'
  };
  const searchVippedQueryVariables = {
    filter: { ...transformedObj.filter, scope: 'VIPPED_PURE' },
    limit: 4,
    sort: 'RANDOM'
  };
  const searchVippedResidencesQueryVariables = {
    filter: { ...transformedObj.filter, scope: 'VIPPED_APARTMENTS' },
    limit: 4,
    sort: 'RANDOM'
  };
  const emptyLayout = (
    <FallbackView
      width={228}
      height={280}
      onClickHandler={resetForm}
      text={t('search.no_results.text')}
      title={t('search.nothing_found')}
      btnText={t('search.no_results.link')}
      Icon={ErrorIcon}
    />
  );

  /*
   * Load  data via hooks
   * */
  const [
    {
      loading: searchItemsQueryLoading,
      data: searchItemsQueryData,
      error: searchItemsError,
      fetchMore
    },
    {
      loading: searchVippedItemsQueryLoading,
      data: searchVippedItemsQueryData,
      error: searchVippedItemsQueryError
    },
    {
      loading: searchVippedResidencesItemsQueryLoading,
      data: searchVippedResidencesItemsQueryData,
      error: searchVippedResidencesItemsQueryError
    }
  ] = (() => {
    const searchQuery = useQuery(searchItemsQuery(searchItemsQueryVariables), {
      variables: searchItemsQueryVariables,
      ssr: false,
      notifyOnNetworkStatusChange: true
    });
    const searchVippedQuery = useQuery(
      searchVippedItemsQuery(searchVippedQueryVariables),
      {
        variables: searchVippedQueryVariables,
        ssr: false,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      }
    );
    const searchVippedResidencesQuery = useQuery(
      searchVippedResidencesItemsQuery(searchVippedResidencesQueryVariables),
      {
        variables: searchVippedResidencesQueryVariables,
        ssr: false,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      }
    );

    return [searchQuery, searchVippedQuery, searchVippedResidencesQuery];
  })();

  /*
   * searchItems
   * */

  const itemsList =
    searchItemsQueryData?.itemsConnection?.edges.map(item => {
      return (
        <ItemCard
          key={item.node.id}
          itemId={itemId}
          setCarousel={setCarousel}
          carousel={true}
          {...item.node}
        />
      );
    }) || [];

  /*
   * vipSearchItems
   * */
  const itemsVipList =
    (searchVippedItemsQueryData?.items &&
      searchVippedItemsQueryData.items.map(item => {
        return (
          <ItemCard
            key={item.id}
            itemId={itemId}
            setCarousel={setCarousel}
            carousel={true}
            {...item}
          />
        );
      })) ||
    [];

  /*
   * vipResidencesSearchItems
   * */
  const itemsVipResidencesList =
    (searchVippedResidencesItemsQueryData?.items &&
      searchVippedResidencesItemsQueryData.items.map(item => {
        return (
          <ItemCard
            key={item.id}
            itemId={itemId}
            setCarousel={setCarousel}
            carousel={true}
            {...item}
          />
        );
      })) ||
    [];

  const placeholder = loading => {
    if (!loading) return null;

    return (
      <SectionPlaceholder>
        <ItemCardPlaceholder />
        <ItemCardPlaceholder />
      </SectionPlaceholder>
    );
  };

  useEffect(() => {
    if (
      searchVippedItemsQueryError ||
      searchVippedResidencesItemsQueryError ||
      searchItemsError
    ) {
      setErrorUI(true);
    }
  });

  return (
    <>
      {searchItemsQueryData &&
        searchItemsQueryData.itemsConnection.totalCount && (
          <NewSearch
            formElToAssign={formEl}
            totalAdsCount={searchItemsQueryData?.itemsConnection.totalCount}
          />
        )}
      {(searchItemsQueryData &&
        !searchItemsQueryLoading &&
        !searchItemsQueryData.itemsConnection.totalCount &&
        emptyLayout) || (
        <>
          {!searchVippedItemsQueryLoading && itemsVipList.length ? (
            <Section
              id="search-page-vipped"
              title={t('sections.vipped.title')}
              url="/items/vipped"
              urlTitle={t('sections.vipped.show_all')}
              sectionAdditionalClasses="section-block center-block"
            >
              <SectionList listAdditionalClasses="section-list_hide-excess-items">
                {itemsVipList}
              </SectionList>
            </Section>
          ) : (
            placeholder(searchVippedItemsQueryLoading)
          )}
          {!searchVippedResidencesItemsQueryLoading &&
          itemsVipResidencesList.length ? (
            <>
              {
                <Section
                  id="search-page-vipped-residences"
                  title={t('sections.vipped_residences.title')}
                  sectionAdditionalClasses="section-block center-block"
                >
                  <SectionList listAdditionalClasses="section-list_hide-excess-items">
                    {itemsVipResidencesList}
                  </SectionList>
                </Section>
              }
            </>
          ) : (
            placeholder(searchVippedResidencesItemsQueryLoading)
          )}
          {!(searchItemsQueryLoading && !itemsList.length) ? (
            <Section
              id="search-page-regular-items"
              title={t('search.ads')}
              sectionAdditionalClasses="section-block center-block"
            >
              <EndlessSearchItems
                items={itemsList || []}
                loading={searchItemsQueryLoading}
                ref={searchListRef}
                isStopLoad={
                  searchItemsQueryData &&
                  !searchItemsQueryData.itemsConnection.pageInfo.hasNextPage
                }
                onLoadMore={() => {
                  const moreSearchItemsQueryVariables = {
                    first: 16,
                    filter: transformedObj.filter,
                    sort: transformedObj.sorting || 'BUMPED_AT_DESC',
                    cursor:
                      searchItemsQueryData.itemsConnection.pageInfo.endCursor
                  };
                  fetchMore({
                    variables: moreSearchItemsQueryVariables
                  });
                }}
              >
                {itemsList}
              </EndlessSearchItems>
            </Section>
          ) : (
            <SectionPlaceholder>
              <ItemCardPlaceholder />
              <ItemCardPlaceholder />
            </SectionPlaceholder>
          )}
        </>
      )}
    </>
  );
};

SearchPage.propTypes = {
  transformedObj: PropTypes.object,
  isEmptySearch: PropTypes.bool,
  searchError: PropTypes.array,
  setErrorUI: PropTypes.func
};

export default connect(null, mapDispatchToProps)(SearchPage);
